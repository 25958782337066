import { ContactPreferenceType } from './contact-information.model';
import { AppointmentStatus } from './transaction.model';

export enum IdUpgradeSectionId {
  ADDITIONAL_FORM_INFORMATION = 'additional-form-information',
  CURRENT_ID = 'current-id',
  PERSONAL_INFORMATION = 'personal-information',
  ID_DETAILS = 'id-details',
  PROOF_OF_SOCIAL_SECURITY = 'proof-of-social-security',
  PROOF_OF_RESIDENCY = 'proof-of-residency',
  PROOF_OF_IDENTITY = 'proof-of-identity',
  PROOF_OF_LEGAL_NAME = 'proof-of-legal-name',
  MV_FOUR_FOUR = 'mv-four-four',
}

export interface RealIdEdlDTO {
  barcodeUrl: string;
  id: number;
  transactionId: string;
  status: string;
  currentFirstName: string;
  currentMiddleName: string;
  currentLastName: string;
  documentIds: number[];
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mailingCounty: string;
  currentIdType: string;
  idRequested: string;
  idType: string;
  appointmentCounty: string;
  appointmentTime: string;
  appointmentReferenceNumber: string;

  /**
   * Used to generate QR codes, this is different from the {@link appointmentReferenceNumber}
   */
  appointmentReservationNumber: string;
  appointmentStatus: AppointmentStatus;
  branchName: string;
  branchAddress: string;
  branchAddress2: string;
  branchCity: string;
  branchState: string;
  branchZip: string;
  createdAt: string;
  updatedAt: string;
  licenseId: string;
  contactPreferenceType: ContactPreferenceType;
  birthDay: string;
  birthMonth: string;
  birthYear: number;
  submittedOn: string;
  applicationId: string;
  rejectionReason: string;
}
